import React, { CSSProperties } from "react";
import { Skeleton } from 'antd';
import '../common/css/ArkreenMinCard.css'
type cardProps = {
    icon?:React.ReactNode,
    title: string,
    href?: string,
    style?:CSSProperties,
    titleColor?:string,
    valueColor?:string,
    children?: React.ReactNode,
}

export default function ArkreenMinCard(prop: cardProps): JSX.Element {
    return (
        <div className="min-card-body" style={prop.style}>
            <div className="right">
                <div className="title" style={{color:prop.titleColor?prop.titleColor:'#40404D'}}>
                    {prop.title}
                </div>
                <div className="content" style={{color:prop.valueColor?prop.valueColor:'#101020'}}>
                    { prop.children }
                </div>
            </div>
        </div>
    )
}
    