import React from 'react';

function AppFooterBar(){

  const footerStyle: React.CSSProperties = {
    textAlign: 'right',
    backgroundColor:'#FFFFFF',
    color:'#000000',
    width:'100%',
    paddingInlineEnd:'30px',
    lineHeight:'48px',
    height:'48px',
    marginTop: '-48px',
  };


  return (
    <div style={footerStyle}>
    </div>
  );
}

export default AppFooterBar;
