import '../common/css/ArkreenPagination.css'
import {LeftIcon} from '../icons/LeftIcon'
import {RightIcon} from '../icons/RightIcon'
import { useNavigate,useLocation } from 'react-router-dom'
import { useState  } from "react";
import { PaginationProps } from 'antd';


export default function ArkreenPagination(prop: PaginationProps): JSX.Element {
    const navigate  = useNavigate()
    const location  = useLocation()
    const [page,setPage] = useState(prop.current?prop.current:1)

    function previous(){
        if(page>1){
            setPage(page-1)
            handleChange(page-1)
        }
    }
    function next(){
        setPage(page+1)
        handleChange(page+1)
    }
    //
    return (
         <div>
            <div  className="pagination-box-pc">
             <div className='pagination-btn' onClick={() => next()}><RightIcon/></div>
             <div className='pagination-btn selected'>{page}</div>
             <div className={page<=1?'pagination-btn disable':'pagination-btn'}  onClick={() => previous()}><LeftIcon/></div>
            </div>
            <div  className="pagination-box-m">
            <div className={page<=1?'pagination-btn disable':'pagination-btn'}  onClick={() => previous()}><LeftIcon/></div>
             <div className='pagination-btn selected'>{page}</div>
             <div className='pagination-btn' onClick={() => next()}><RightIcon/></div>
            </div>
         </div>
    )

    function handleChange(page:number){
        prop.onChange?.call(null, page, 1)
        let data = new URLSearchParams(location.search);
        data.set('page',""+page);
        navigate("?"+data.toString());
    }
}