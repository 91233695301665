import React from "react";
import { Layout, ConfigProvider } from "antd";
import { ErrorBoundary } from "./ErrorBoundary";
import AppTopBar from "./AppTopBar";
import AppFooterBar from "./AppFooterBar";
import WebSites from "../routes/WebSites";
import { Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { inject } from '@vercel/analytics';

inject();

const { Content } = Layout;

function ExplorerBody() {
  return (
    <>
      <ConfigProvider
        direction="ltr"
        theme={{
          token: {
            fontFamily: "Montserrat",
          },
        }}
      >
        <ErrorBoundary>
          <RecoilRoot>
            <Layout style={{}}>
              <Layout>
                <Content style={{ overflow: "auto" }}>
                  <AppTopBar />
                  <div style={{ minHeight: "100%", paddingBottom: "48px" }}>
                    <Routes>
                      <Route
                        path="/"
                        element={<React.Suspense children={<WebSites />} />}
                      />
                    </Routes>
                  </div>
                  <AppFooterBar />
                </Content>
              </Layout>
            </Layout>
          </RecoilRoot>
        </ErrorBoundary>
      </ConfigProvider>
    </>
  );
}

export default ExplorerBody;
