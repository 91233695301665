import React,{ useState  } from 'react';
import { Layout,Input,Space,Divider,message, Image  } from 'antd';
import {SearchIcon} from '../icons/SearchIcon'
import ArkreenButton from '@/components/ArkreenButton';
import './css/PageTop.css'
import {MenuIcon} from '../icons/MenuIcon'
import {ArkreenLogoSingleIcon} from '../icons/ArkreenLogoSingleIcon'
import {ArkreenLogoTextUnionIcon} from '../icons/ArkreenLogoTextUnionIcon'
import {CloseMenuIcon} from '../icons/CloseMenuIcon'
import {AccountLineIcon} from '../icons/AccountLineIcon'
import {MinerLineIcon} from '../icons/MinerLineIcon'
import {DashboardLineIcon} from '../icons/DashboardLineIcon'
import {TransactionLineIcon} from '../icons/TransactionLineIcon'
import {BlockLineIcon} from '../icons/BlockLineIcon'
import {ArkreenHomeIcon} from '../icons/ArkreenHomeIcon'
import {ArkreenConsoleIcon} from '../icons/ArkreenConsoleIcon'
import {ArkreenDocsIcon} from '../icons/ArkreenDocsIcon'
import {useNavigate } from 'react-router-dom'
import {jumpTo} from '../utils/BrowserUtils'
const { Header } = Layout;

function AppTopBar(){

  const search_style: React.CSSProperties = {
    display:'none'
  };

  function showMenu(){
    const style: React.CSSProperties = {
      position:'absolute',
      top:'64px',
      background:'#FFFFFF',
      width:'100%',
      height:'-webkit-fill-available',
      color:'#000000',
      zIndex:'999',
      display:menuStyle.display==='none'?'block':'none',
    };
    setMenuStyle(style)
  }

  const menu_style: React.CSSProperties = {
    position:'absolute',
    top:'64px',
    background:'#FFFFFF',
    width:'100%',
    height:'-webkit-fill-available',
    color:'#000000',
    zIndex:'999',
    display:'none'
  };

  const [menuStyle,setMenuStyle] = useState(menu_style)
  const [searchStyle,setSearchStyle] = useState(search_style)
  const navigate  = useNavigate()
  function gotoHome(){
    if(menuStyle.display!=='none'){
      showMenu();
    }
    navigate('/')
  }

  const menuList = [];
  const dashboard = {
    key: '',
    sort: '1',
    icon: <DashboardLineIcon/>,
    label: 'Dashboard',
    url: '/'
  }
  const accounts = {
    key: 'accounts',
    sort: '2',
    icon: <AccountLineIcon/>,
    label: 'Accounts',
    url: '/accounts'
  }
  const miners = {
    key: 'miners',
    sort: '3',
    icon: <MinerLineIcon/>,
    label: 'Miners',
    url: '/miners'
  }
  const transactions = {
    key: 'transactions',
    sort: '4',
    icon: <TransactionLineIcon />,
    label: 'Transactions',
    url: '/txs'
  }
  const blocks = {
    key: 'blocks',
    sort: '5',
    icon: <BlockLineIcon/>,
    label: 'Blocks',
    url: '/blocks',
  }
  menuList.push(dashboard)
  menuList.push(accounts)
  menuList.push(miners)
  menuList.push(transactions)
  menuList.push(blocks)


  return (
    <Header className='headerStyle'>
      <div className='top-menu-box-m'>
            <Space>
              <div style={{paddingLeft:'3px'}}>
                <div style={{ color:"black", fontSize:"25px"}}>AIGC.IT</div>
              </div>
              <span  style={{ color:"black", marginRight: "25px", fontSize:"14px", marginTop: "4px"}}>ChatGPT国内免费地址</span>
            </Space>
      </div>
      <div className='top-menu-box-pc'>
            <Space>
                <div style={{ color:"black", marginLeft: "25px", fontSize:"25px"}}>AIGC.IT</div>
                <span  style={{ color:"black", marginRight: "25px", fontSize:"14px"}}>ChatGPT国内免费地址</span>
            </Space>
      </div>
    </Header>
  );
}

export default AppTopBar;
