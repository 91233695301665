import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import type { ColumnsType } from "antd/es/table";
import "../common/css/LinkButton.css";
import "../common/css/ArkreenMinViewTable.css";
import "../common/css/ArkreenTable.css";
import "../common/css/ArkreenMain.css";
import ArkreenTable from "../components/ArkreenTable";

import list from "../data/chatgpt-alive-list.json";
import axios from "axios";
import { WebSiteType } from "@/store/types";
import { Spin, Tag, Image, Space } from "antd";
import { EllipseIcon } from "@/icons/EllipseIcon";
import Link from "antd/es/typography/Link";
import { Card } from "antd";
import { Col, Row } from "antd";
import ArkreenMinCard from "@/components/ArkreenMinCard";
import Title from "antd/es/typography/Title";


const { Meta } = Card;

function Accounts() {
  const location = useLocation();

  const columns: ColumnsType<WebSiteType> = [
    {
      title: "名字",
      dataIndex: "name",
      key: "name",
      width: "100px",
      fixed: "left",
    },
    {
      title: "地址",
      dataIndex: "url",
      key: "url",
      width: "180px",
      fixed: "left",
    },
    {
      title: "可用",
      dataIndex: "health",
      key: "health",
      width: "100px",
    },
    {
      title: "延时",
      dataIndex: "delay",
      key: "delay",
      width: "100px",
    },
  ];

  const utm = new URLSearchParams(location.search);
  const [accounts, setAccounts] = useState([]);
  const [currentPage, setCurrentPage] = useState(
    utm.get("page") ? Number(utm.get("page")) : 1
  );

  async function health(url: string, item: any) {
    const start = Date.now();
    return axios
      .get(`${url}?r=${Math.random()}`, { timeout: 5000 })
      .then((resp) => {
        console.log(resp.status);
        const end = Date.now();
        if (resp.status === 200) {
          item.health = (
            <Tag
              icon={<EllipseIcon />}
              color=" #E8F9F9"
              style={{ color: "#19BEBE" }}
            >
              可用
            </Tag>
          );
        } else {
          item.health = (
            <Tag
              icon={<EllipseIcon />}
              color="red"
              style={{ color: "#19BEBE" }}
            >
              不可用
            </Tag>
          );
        }
        item.delay = end - start + "ms";
      })
      .catch((e) => {
        const end = Date.now();
        item.health = (
          <Tag icon={<EllipseIcon />} color="red" style={{ color: "#19BEBE" }}>
            不可用
          </Tag>
        );
        item.delay = end - start + "ms";
      });
  }

  async function handleWebSites() {
    const tb: any = [];
    for (let i = 0; i < list.length; i++) {
      const item = list[i] as WebSiteType;
      const tbItem: any = {};
      tbItem._url = item.url;
      tbItem.url = (
        <Link className="content-td-link" href={item.url} target="_blank">
          {item.url}
        </Link>
      );
      tbItem.name = item.name;
      tbItem.health = <Spin />;
      tbItem.stability = item.stability;
      tbItem.free = item.free;
      tb.push(tbItem);
    }
    setAccounts(tb as any);

    const ps = [];
    for (let i = 0; i < tb.length; i++) {
      const tbItem = tb[i];
      const p = health(tbItem._url, tbItem).then((e) => {
        const tb2 = [...tb];
        setAccounts(tb2 as any);
      });
      ps.push(p);
    }
    await Promise.all(ps);
  }

  useEffect(() => {
    handleWebSites();
  }, [currentPage]); //eslint-disable-line

  return (
    <div className="arkreen-main">

      <div className="main-top-content" style={{ marginBottom: "20px",borderRadius: "8px" }}>
        <Card
          style={{ width: 300 }}
          cover={
            <Image
              src="/wx_aihunter_3-min.png"
              width={300}
              style={{ marginRight: "25px" }}
            ></Image>
          }
        >
          <Meta
            description={<span>小手点一点关注，
            免费获取网址库，<br/>
            日日维护保运行，
            起飞路上不迷路。</span>}
          />
        </Card>
      </div>

      <Row gutter={[20, 20]} style={{marginTop:'20px'}}>
        <Col
          className="gutter-row"
          xs={24}
          sm={12}
          md={12}
          lg={8}
          xl={8}
          xxl={8}
        >
          <ArkreenMinCard title="导航主地址">
            <Link href="https://aigc.it" target="_blank">
              https://aigc.it
            </Link>
          </ArkreenMinCard>
        </Col>
        <Col
          className="gutter-row"
          xs={24}
          sm={12}
          md={12}
          lg={8}
          xl={8}
          xxl={8}
        >
          <ArkreenMinCard title="导航备用地址">
            <Link href="https://did.fm" target="_blank">
              https://did.fm
            </Link>
          </ArkreenMinCard>
        </Col>
        <Col
          className="gutter-row"
          xs={24}
          sm={12}
          md={12}
          lg={8}
          xl={8}
          xxl={8}
        >
          <ArkreenMinCard title="导航备用地址">
            <Link href="https://dapp.cat" target="_blank">
              https://dapp.cat
            </Link>
          </ArkreenMinCard>
        </Col>
      </Row>

      <div className="arkreen-main-content"  style={{marginTop:'20px'}}>
        <ArkreenTable
          scroll={{ x: 760 }}
          currentPage={currentPage}
          columns={columns}
          datalist={accounts}
          loading={false}
          onPageChange={setCurrentPage}
        />
      </div>
    </div>
  );
}

export default Accounts;
